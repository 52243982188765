import React, { useState } from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import TextError from "../common/textError"
import axios from "axios"
import { Link } from "gatsby"
import { successColor } from "./../../static/styles/colors"

const initialValues = {
  Betrieb: "",
  Anrede: "",
  Vorname: "",
  Nachname: "",
  Mitarbeiterzahl: "",
  Email: "",
  Telefonnummer: "",
  Anliegen: "",
  Nachricht: "",
  Datenschutz: false,
  HoneyPot: "",
  dataSendFrom: "contactSite",
}

const validationSchema = Yup.object({
  Betrieb: Yup.string().required("Betrieb-Name muss ausgefüllt sein!"),
  Nachname: Yup.string().required("Nachname muss ausgefüllt sein!"),
  Email: Yup.string()
    .email("E-Mail-Adresse ist nicht valide")
    .required("E-Mail-Adresse muss ausgefüllt sein!"),
  Anliegen: Yup.string().nullable().required("Bitte ein Anliegen auswählen!"),
  Datenschutz: Yup.boolean().oneOf(
    [true],
    "Bitte die Datenschutzbestimmungen akzeptieren!"
  ),
})

const ContactForm = () => {
  const [serverState, setServerState] = useState()
  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg })
  }

  const onSubmit = (values, submitProps) => {
    axios
      .post("https://fachhandwerk360.de/webmailer/kontakt.php", values)
      .then(response => {
        submitProps.setSubmitting(false)
        submitProps.resetForm()
        handleServerResponse(
          true,
          "Vielen Dank für Ihre Zusendung. Wir werden uns so schnell wie möglichen bei Ihnen melden."
        )
      })
      .catch(error => {
        submitProps.setSubmitting(false)
        handleServerResponse(false, error.response.data.error)
      })
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {formik => {
        return (
          <div className="container p-sm-5 p-3 pt-0">
            <Form>
              <div className="form-group mt-3">
                <Field
                  type="text"
                  id="HoneyPot"
                  name="HoneyPot"
                  className="form-control border-success border-3 d-none"
                />
                <label htmlFor="Betrieb" className="text-light">
                  Betrieb-Name*
                </label>
                <Field
                  type="text"
                  id="Betrieb"
                  name="Betrieb"
                  placeholder="Ihr Betrieb-Name"
                  className="form-control border-success border-3"
                />
                <ErrorMessage name="Betrieb" component={TextError} />
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group mt-3">
                    <label htmlFor="Anrede" className="text-light">
                      Anrede
                    </label>
                    <Field
                      as="select"
                      name="Anrede"
                      id="Anrede"
                      className="form-control border-success border-3"
                    >
                      <option value={null}>-Bitte wählen-</option>
                      <option value="Herr">Herr</option>
                      <option value="Frau">Frau</option>
                      <option value="Divers">Divers</option>
                    </Field>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-3">
                    <label htmlFor="Vorname" className="text-light">
                      Vorname
                    </label>
                    <Field
                      type="text"
                      id="Vorname"
                      name="Vorname"
                      placeholder="Ihr Vorname"
                      className="form-control border-success border-3"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-3">
                    <label htmlFor="Nachname" className="text-light">
                      Nachname*
                    </label>
                    <Field
                      type="text"
                      id="Nachname"
                      name="Nachname"
                      placeholder="Ihr Nachname"
                      className="form-control border-success border-3"
                    />
                    <ErrorMessage name="Nachname" component={TextError} />
                  </div>
                </div>
              </div>
              <div className="form-group mt-3">
                <label htmlFor="Mitarbeiterzahl" className="text-light">
                  Mitarbeiterzahl
                </label>
                <Field
                  as="select"
                  name="Mitarbeiterzahl"
                  id="Mitarbeiterzahl"
                  className="form-control border-success border-3"
                >
                  <option value={null}>-Bitte wählen-</option>
                  <option value="0-4">0-4</option>
                  <option value="5-19">5-19</option>
                  <option value="20-199">20-199</option>
                  <option value="200-349">200-349</option>
                  <option value="350-999">350-999</option>
                  <option value="1000+">1000+</option>
                </Field>
              </div>
              <div className="form-group mt-3">
                <label htmlFor="Email" className="text-light">
                  E-Mail-Adresse*
                </label>
                <Field
                  type="email"
                  id="Email"
                  name="Email"
                  placeholder="Ihre E-Mail-Adresse"
                  className="form-control border-success border-3"
                />
                <ErrorMessage name="Email" component={TextError} />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="Telefonnummer" className="text-light">
                  Telefonnummer
                </label>
                <Field
                  type="text"
                  id="Telefonnummer"
                  name="Telefonnummer"
                  placeholder="Ihre Telefonnummer"
                  className="form-control border-success border-3"
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="Anliegen" className="text-light">
                  Anliegen*
                </label>
                <Field
                  as="select"
                  name="Anliegen"
                  id="Anliegen"
                  className="form-control border-success border-3"
                >
                  <option value={null}>-Bitte wählen-</option>
                  <option value="Unverbindliches Erstgespräch">
                    Unverbindliches Erstgespräch vereinbaren
                  </option>
                  <option value="Flyer anfordern">Flyer anfordern</option>
                  <option value="Preiseliste für Seminare anfordern">
                    Preiseliste für Seminare anfordern
                  </option>
                  <option value="Webdemo vereinbaren">
                    Webdemo vereinbaren
                  </option>
                  <option value="Persönlichen Rückruf anfragen">
                    Persönlichen Rückruf anfragen
                  </option>
                  <option value="Sonstiges">Sonstiges</option>
                </Field>
                <ErrorMessage name="Anliegen" component={TextError} />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="Nachricht" className="text-light">
                  Nachricht
                </label>
                <Field
                  as="textarea"
                  id="Nachricht"
                  name="Nachricht"
                  placeholder="Ihr Anliegen"
                  cols="40"
                  rows="3"
                  className="form-control border-success border-3"
                />
              </div>
              <div className="form-group mt-3">
                <label
                  htmlFor="Datenschutz"
                  className="text-gray-500 font-bold"
                >
                  <Field
                    name="Datenschutz"
                    id="Datenschutz"
                    className="me-2 leading-tight"
                    type="checkbox"
                  />
                  <span className="text-light">
                    Ich habe die{" "}
                    <Link
                      to="/datenschutzerklaerung"
                      style={{ color: successColor }}
                    >
                      Datenschutzrichtlinien
                    </Link>{" "}
                    gelesen und akzeptiere diese.
                  </span>
                </label>
                <br />
                <ErrorMessage name="Datenschutz" component={TextError} />
              </div>
              <button
                className="shadow-inset-success-sm pt-2 pb-2 rounded-0 mt-3 d-block btn btn-secondary text-uppercase text-success border-success"
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                Anfrage Senden
              </button>
              {serverState && (
                <div className="fs-5 text-light mt-3">{serverState.msg}</div>
              )}
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}

export default ContactForm
